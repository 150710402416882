exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accessibility-and-language-services-js": () => import("./../../../src/pages/accessibility-and-language-services.js" /* webpackChunkName: "component---src-pages-accessibility-and-language-services-js" */),
  "component---src-pages-client-referral-js": () => import("./../../../src/pages/client-referral.js" /* webpackChunkName: "component---src-pages-client-referral-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insurance-js": () => import("./../../../src/pages/insurance.js" /* webpackChunkName: "component---src-pages-insurance-js" */),
  "component---src-pages-listed-physician-js": () => import("./../../../src/pages/listed-physician.js" /* webpackChunkName: "component---src-pages-listed-physician-js" */),
  "component---src-pages-locators-4-kscore-test-center-js": () => import("./../../../src/pages/locators/4kscore-test-center.js" /* webpackChunkName: "component---src-pages-locators-4-kscore-test-center-js" */),
  "component---src-pages-locators-js": () => import("./../../../src/pages/locators.js" /* webpackChunkName: "component---src-pages-locators-js" */),
  "component---src-pages-patients-js": () => import("./../../../src/pages/patients.js" /* webpackChunkName: "component---src-pages-patients-js" */),
  "component---src-pages-physician-js": () => import("./../../../src/pages/physician.js" /* webpackChunkName: "component---src-pages-physician-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-prostate-cancer-js": () => import("./../../../src/pages/prostate-cancer.js" /* webpackChunkName: "component---src-pages-prostate-cancer-js" */),
  "component---src-pages-science-behind-the-score-js": () => import("./../../../src/pages/science-behind-the-score.js" /* webpackChunkName: "component---src-pages-science-behind-the-score-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-what-is-the-4-kscore-js": () => import("./../../../src/pages/what-is-the-4kscore.js" /* webpackChunkName: "component---src-pages-what-is-the-4-kscore-js" */)
}

