  const addScript = url => {
    const script = document.createElement("script")
    script.src = url
    script.async = false
    script.defer = true
    document.body.appendChild(script)
  }
  export const onClientEntry = () => {
    window.onload = () => {
      addScript("/jquery-3.6.4.js");
      addScript("/fresco.js");
      addScript("/extra.js");
      addScript("https://embed.typeform.com/embed.js");
    }
  }